<template>
<main>
    <section class="main-wrapper ff-roboto">
        <header class="header-group">
            <v-row>
                <v-col md="6"> <h1 class="header-title">
                    Your application
                </h1></v-col>
                <v-col md="6" class="text-right"><v-btn dark x-large class="ml-2" de  @click="logout">Logout</v-btn></v-col>
            </v-row>


        </header>
        <v-row>
            <v-col xs="12" md="12" v-if="enrolment">

                <div class="form">
                    <div class="profile-picture text-right" v-if="profilePictureUrl">
                        <img :src="profilePictureUrl" alt="Profile Picture">
                    </div>
                    <h3>Enrolment details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">

                    <div class="custom-alert info" v-if="!enrolment.result_status && entranceDateStatus=='today'">
                        You entrance exam is scheduled for today. Please attend it on time.
                    </div>
                    <div class="custom-alert info" v-if="!enrolment.result_status && entranceDateStatus=='future'">
                        All the best for your entrance exam on {{enrolment.formatted_selected_entrance_date}}.
                    </div>

                    <div class="custom-alert warning"  v-if="entranceResult && entranceResult.type =='absent'">
                        <p v-html="entranceResult.message"></p>
                    </div>
                    <div class="custom-alert success" v-if="entranceResult && entranceResult.type =='pass'">
                        <p v-html="entranceResult.message"></p>
                    </div>

                    <div class="custom-alert danger" v-if="entranceResult && entranceResult.type =='fail'">
                        <p v-html="entranceResult.message"></p>
                    </div>
                    <div class="custom-alert info" v-if="enrolment.interview_time && enrolment.result_status && enrolment.is_paid && parseInt(enrolment.obtained_marks) >= parseInt(enrolment.eligible_marks_for_process) && entranceResult && entranceResult.type =='pass'">
                        This is to confirm that your interview has been scheduled for {{ enrolment.formatted_interview_date }} at {{enrolment.formatted_interview_time}}.
                        Please let us know if you need to reschedule or if you have any questions. We hope to see you soon!
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="" class="form-label">Enrolment No:</label>
                        <input type="text" class="form-control" :value="enrolment.symbol_no" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Enrolment PIN:</label>
                        <input type="text" class="form-control" :value="enrolment.enrolment_pin" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Entrance exam date <a href="#" @click="downloadAdmitCard" v-if="enrolment.is_paid"><i class="fa fa-id-badge ml-5" ></i> </a></label>
                        <input type="text" class="form-control" :value="enrolment.formatted_selected_entrance_date" readonly/>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Entrance exam time</label>
                        <input type="text" class="form-control" :value="enrolment.entrance_start_time" readonly/>
                    </div>
                    <div class="form-group" v-if="enrolment.building_desc">
                        <label for="" class="form-label">Entrance exam building</label>
                        <input type="text" class="form-control" :value="enrolment.building_desc" readonly/>
                    </div>
                    <div class="form-group" v-if="enrolment.room_desc">
                        <label for="" class="form-label">Entrance exam room</label>
                        <input type="text" class="form-control" :value="enrolment.room_desc" readonly/>
                    </div>
                    <div class="form-group" v-if="enrolment.formatted_interview_date && enrolment.result_status">
                        <label for="" class="form-label">Interview date</label>
                        <input type="text" class="form-control" :value="enrolment.formatted_interview_date ? enrolment.formatted_interview_date : 'NA'" readonly/>
                    </div>
                    <div class="form-group" v-if="enrolment.formatted_interview_time && enrolment.result_status">
                        <label for="" class="form-label">Interview time</label>
                        <input type="text" class="form-control" :value="enrolment.formatted_interview_time ? enrolment.formatted_interview_time : 'NA'" readonly/>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Stream</label>
                        <input type="text" class="form-control" :value="enrolment.program" readonly/>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Date of submission</label>
                        <input type="text" class="form-control" :value="enrolment.submitted_date" readonly/>
                    </div>

                    <div class="form-group">
                        <label for="" class="form-label">Verification status</label>
                        <span class="form-control mt-3">
                            <span class="badge badge-success" v-if="enrolment.is_approved">Verified</span>
                            <span class="badge badge-warning" v-else>Not verified</span>
                        </span>
                    </div>
                    <div class="form-group" v-if="enrolment.exam_status && enrolment.is_exam_conducted">
                        <label for="" class="form-label">Exam status</label>
                        <span class="form-control mt-3">
                            <span class="badge badge-success" v-if="enrolment.exam_status=='attended'">Taken</span>
                            <span class="badge badge-warning" v-else>Not taken</span>
                        </span>
                    </div>
                    <div class="form-group" v-if="enrolment.is_exam_conducted && entranceResult && entranceResult.type =='pass'">
                        <label for="" class="form-label">Result status</label>
                        <span class="form-control mt-3">
                            <span class="badge badge-success" v-if="enrolment.result_status">Published</span>
                            <span class="badge badge-danger" v-else>Unpublished</span>

                        </span>
                    </div>
                    <div class="form-group" v-if="enrolment.offer_letter_visible && entranceResult && entranceResult.type =='pass'">
                        <label for="" class="form-label">Offer letter</label>
                        <span class="form-control mt-3">

                            <a @click="downloadOfferLetter" download>
                                <div class="offer-letter-icon-container">
                                    <i style="color: white" class="fa fa-download"></i>
                                </div>

                            </a>


                        </span>
                    </div>
                    <div class="form-group" v-if="enrolment.is_approved && enrolment.result_status && enrolment.is_paid && parseInt(enrolment.obtained_marks) >= parseInt(enrolment.eligible_marks_for_process) && entranceResult && entranceResult.type =='pass'">
                        <label for="" class="form-label">Accept offer</label>
                        <span class="form-control mt-3">
                            <span v-if="enrolment.enrolment_offer_accepted" class="badge badge-success" >Accepted</span>
                            <span v-else class="badge badge-success" >Not accepted</span>
<!--                            <v-btn :loading="acceptLoading" v-else @click="acceptEnrolmentOffer" depressed color="indigo" dark>Accept</v-btn>-->
                        </span>
                    </div>
                    <div class="form-group" v-if="enrolment.enrolment_offer_accepted && enrolment.academic_class_title ">
                        <label for="" class="form-label">Assigned class</label>
                        <input type="text" class="form-control" :value="enrolment.academic_class_title" readonly/>
                    </div>
                    <div class="form-group" v-if="enrolment.enrolment_offer_accepted && enrolment.academic_class_start_date">
                        <label for="" class="form-label">Assigned class start from</label>
                        <input type="text" class="form-control" :value="enrolment.academic_class_start_date" readonly/>
                    </div>
                    <div class="form-group" v-if="enrolment.orientation_date &&  parseInt(enrolment.obtained_marks) >= parseInt(enrolment.eligible_marks_for_process) &&  enrolment.result_status">
                        <label for="" class="form-label">Date of orientation</label>
                        <input type="text" class="form-control" :value="enrolment.orientation_date" readonly/>
                    </div>

                    <h3>Personal details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    <div class="form-group">
                        <label for="" class="form-label">Name of the Applicant</label>
                        <input type="text" class="form-control" :value="enrolment.full_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Gender</label>
                        <input type="text" class="form-control" :value="enrolment.gender_text" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Date of Birth (BS)</label>
                        <input type="text" class="form-control" :value="enrolment.dob_bs" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Date of Birth (AD)</label>
                        <input type="text" class="form-control" :value="basic_info.dob" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Nationality</label>
                        <input type="text" class="form-control" :value="ucwords(enrolment.nationality)" readonly>
                    </div>
                    <h3>Permanent address details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    <div class="form-group">
                        <label for="" class="form-label">Country</label>
                        <input type="text" class="form-control" :value="ucwords(basic_info.country)" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Province</label>
                        <input type="text" class="form-control" :value="enrolment.province_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">District</label>
                        <input type="text" class="form-control" :value="enrolment.district_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Local level</label>
                        <input type="text" class="form-control" :value="enrolment.municipality_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Ward</label>
                        <input type="text" class="form-control" :value="enrolment.ward_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Area/tole</label>
                        <input type="text" class="form-control" :value="enrolment.area_title" readonly>
                    </div>
                    <h3>Temporary address details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    <div class="form-group">
                        <label for="" class="form-label">Country</label>
                        <input type="text" class="form-control" :value="ucwords(basic_info.temporary_country)" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Province</label>
                        <input type="text" class="form-control" :value="enrolment.temporary_province_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">District</label>
                        <input type="text" class="form-control" :value="enrolment.temporary_district_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Local level</label>
                        <input type="text" class="form-control" :value="enrolment.temporary_municipality_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Ward</label>
                        <input type="text" class="form-control" :value="enrolment.temporary_ward_name" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Area/tole</label>
                        <input type="text" class="form-control" :value="enrolment.temporary_area_title" readonly>
                    </div>
                    <h3>Contact details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    <div class="form-group">
                        <label for="" class="form-label">Phone</label>
                        <input type="text" class="form-control" :value="enrolment.phone" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Mobile</label>
                        <input type="text" class="form-control" :value="enrolment.mobile" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Email</label>
                        <input type="text" class="form-control" :value="enrolment.email" readonly>
                    </div>
                    <div class="page_break"></div>
                    <h3>Parent/guardian details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    <div class="form-group">
                        <label for="" class="form-label">Guardian name</label>
                        <input type="text" class="form-control" :value="basic_info.guardian_name ? basic_info.guardian_name : 'NA'" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Guardian contact</label>
                        <input type="text" class="form-control" :value="basic_info.guardian_contact_number ? basic_info.guardian_contact_number : 'NA'" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Father name</label>
                        <input type="text" class="form-control" :value="basic_info.father_name ? basic_info.father_name : 'NA'" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Father contact</label>
                        <input type="text" class="form-control" :value="basic_info.father_contact_number ? basic_info.father_contact_number : 'NA'" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Mother name</label>
                        <input type="text" class="form-control" :value="basic_info.mother_name ? basic_info.mother_name : 'NA'" readonly>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Mother contact</label>
                        <input type="text" class="form-control" :value="basic_info.mother_contact_number ? basic_info.mother_contact_number : 'NA'" readonly>
                    </div>
                    <h3>Other details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    <div class="form-group">
                        <label for="" class="form-label">Shift</label>
                        <input type="text" class="form-control" :value="ucwords(shifts)" readonly>
                    </div>

                    <div class="form-group" v-if="enrolment && enrolment.compulsory_courses">
                        <label for="" class="form-label">Compulsory courses</label>
<!--                        <input type="text" class="form-control" :value="enrolment.gender_text">-->


                      <v-col :class="$vuetify.breakpoint.mobile ? ' justify-content-around mt-5' :'justify-content-around mt-5'" xs="12" >
                                <span class="badge-compulsary-course ml-2 mt-2"  v-for="(compulsory_course,index) in enrolment.compulsory_courses" :key="index">
                                 {{ compulsory_course }}
                              </span>
                      </v-col>
                    </div>

                    <div class="form-group">
                        <label for="" class="form-label">Selected courses</label>
                        <div class="form-control mt-3">
                            <span class="badge badge-course mr-3 mb-2" v-if="educational_information && educational_information.selectable_course_1">{{educational_information.selectable_course_1}}</span>
                            <span class="badge badge-course mr-3 mb-2" v-if="educational_information && educational_information.selectable_course_2">{{educational_information.selectable_course_2}}</span>
                            <span class="badge badge-course mr-3 mb-2" v-if="educational_information && educational_information.selectable_course_3">{{educational_information.selectable_course_3}}</span>
                            <span class="badge badge-course mr-3 mb-2" v-if="educational_information && educational_information.selectable_course_4">{{educational_information.selectable_course_4}}</span>
                        </div>
                    </div>

                    <h3>Education details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    
                    <table class="table table-responsive" id="education-detail-list" bordered>
                        <thead v-show="educational_information && educational_information.academic_backgrounds.length > 0" v-for="(academic,index) in enrolment.customize_user_academic_backgrounds" :key="index">
                            <tr>
                                <th style="max-width: 170px !important; white-space: pre-wrap;" class="px-1">Institution name</th>
                                <td class="pt-1 px-1" style="max-width: 270px !important; white-space: pre-wrap;">
                                    <div class="font-weight-medium">
                                        {{academic.institute_name}}
                                    </div>
                                    <small v-if="academic.address" class="font-weight-medium" v-html="academic.address"></small>
                                </td>
                            </tr>
                            <tr>
                                <th style="max-width: 170px !important; white-space: pre-wrap;" class="px-1">Completed year</th>
                                <td class="pt-3 px-2" style="max-width: 170px !important; white-space: pre-wrap;">
                                    <div class="font-weight-medium">
                                    {{academic.completed_year}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="px-1" style="max-width: 170px !important; white-space: pre-wrap;">Exam board</th>
                                <td class="pt-3 px-2" style="max-width: 170px !important; white-space: pre-wrap;">
                                    <div class="font-weight-medium">
                                        {{academic.exam_board}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="px-1" style="max-width: 170px !important; white-space: pre-wrap;">GPA</th>
                                <td class="pt-3 px-2" style="max-width: 170px !important; white-space: pre-wrap;">
                                    <div class="font-weight-medium">
                                        {{academic.gpa}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="px-1" style="max-width: 170px !important; white-space: pre-wrap;">Symbol no.</th>
                                <td class="pt-3 px-2" style="max-width: 170px !important; white-space: pre-wrap;">
                                    <div class="font-weight-medium">
                                        {{academic.symbol_no}}
                                    </div>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    
                    <table id="education-detail-table" width="95%" class="bordered">
                        <thead>
                        <tr>

                            <th>Institute name</th>
                            <th>Completed year</th>
                            <th>Exam board</th>
                            <th>GPA</th>
                            <th>Symbol no.</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-show="educational_information && educational_information.academic_backgrounds.length > 0" v-for="(academic,index) in enrolment.customize_user_academic_backgrounds" :key="index">

                            <td>
                                <div class="font-weight-medium">
                                    {{academic.institute_name}}
                                </div>

                                <small v-if="academic.address" class="font-weight-medium" v-html="academic.address"></small>
                            </td>
                            <td>
                               <span>
                                   {{academic.completed_year}}
                               </span>

                            </td>
                            <td> {{academic.exam_board}}</td>
                            <td>{{academic.gpa}}</td>
                            <td>{{academic.symbol_no || 'N/A'}}</td>
                        </tr>

                        </tbody>
                    </table>

                    <h3>Payment details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                    <div class="form-group">
                        <label for="" class="form-label">Payment status  </label>
                        <span class="form-control ">
                    <span class="badge badge-success" v-if="enrolment.is_paid">Paid</span>
                    <span class="badge badge-warning" v-else>Pending payment</span>
                </span>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Payment method</label>
                        <input type="text" class="form-control" :value="ucwords(enrolment.payment_method)" readonly/>
                    </div>
                    <div class="form-group">
                        <label for="" class="form-label">Payment amount (Rs)</label>
                        <input type="text" class="form-control" :value="enrolment.paid_amount" readonly/>
                    </div>
                    <div class="form-group">
                        <h3>Other details</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                        <div class="form-group">
                            <label for="" class="form-label">Hostel facility</label>
                            <input type="text" class="form-control" :value="other_information && other_information.hostel_facility ? 'Yes' :' No'" readonly/>
                        </div>
                        <div class="form-group">
                            <label for="" class="form-label">Transportation facility</label>
                            <input type="text" class="form-control" :value="other_information && other_information.transportation_facility ? 'Yes' :' No'" readonly/>
                        </div>
                        <div class="form-group" v-if="other_information && other_information.pickup_location">
                            <label for="" class="form-label">Pickup location </label>
                            <input type="text" class="form-control" :value="other_information.pickup_location" readonly/>
                        </div>

                      <div class="form-group" v-if="other_information && other_information.recomended_sources && other_information.recomended_sources.length > 0">
                        <label for="" class="form-label">Recommended sources</label>
                        <v-col :class="$vuetify.breakpoint.mobile ? ' justify-content-around mt-5' :'justify-content-around mt-5'" xs="12" >
                                <span class="badge-recomended-source ml-2"  v-for="(recomended_source,index) in  other_information.recomended_sources"
                                      :key="index">
                                 {{ recomended_source }}
                              </span>
                        </v-col>
                      </div>


                    </div>
                    <div class="form-group">
                        <h3>Documents</h3><hr style="border: 1px solid #4976a6; margin-bottom: 20px;">
                        <table width="95%" class="bordered">
                            <thead>
                            <tr>

                                <th>Document type</th>

                                <th></th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr v-for="(document,index) in documents" :key="index">

                                <td>{{ucwords(document.type)}}</td>

                                <td>

                                    <a target="_blank" v-if="document && document.image_path" :href="document.image_path.real" >
                                        <i :class="getFileType(document.image)"></i>
                                        Open</a>
                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </v-col>
        </v-row>

    </section>
</main>
</template>
<script>
import {ColorsVariableMixin} from "@/mixins/ColorsVariableMixin";
import EssentialService from "@/core/services/enrollment/EssentialService";
const enrollmentEssentialService=new EssentialService();
export default {
    mixins:[ColorsVariableMixin],
    data(){
        return{
            enrolment:null,
            more_details:null,
            basic_info:null,
            educational_information:null,
            showResult:false,
            entranceResult:null,
            offset: true,
            shifts:null,
            profilePictureUrl: null,
            documents:null,
            acceptLoading:false,
            other_information:null,
        }
    },
    methods:{
        logout(){
            localStorage.removeItem('application_key');
            this.$router.push({name: 'index'});
        },
        ucwords(value) {
            if (!value) return ''
            return value.toString().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        },
        getUserEnrolmentInfoDetail(){
            let id =  localStorage.getItem('application_key');
            enrollmentEssentialService
                .getUserEnrollmentDetail(id)
                .then(response => {
                    this.enrolment=response.data.enrolment;
                    if(this.enrolment && this.enrolment.user_document_photo){
                        this.profilePictureUrl=this.enrolment.user_document_photo;
                    }
                    if(this.enrolment && this.enrolment.more_details){
                        this.more_details=JSON.parse(this.enrolment.more_details);
                        if(this.more_details && this.more_details.other_information){
                            this.other_information=this.more_details.other_information;
                        }
                        if(this.more_details && this.more_details.educational_information){
                            this.educational_information=this.more_details.educational_information;

                           if(this.educational_information && this.educational_information.shifts){
                               this.shifts=this.educational_information.shifts;
                           }else if(this.educational_information && this.educational_information.shift){
                               this.shifts=this.educational_information.shift;
                           }else{
                               this.shifts='N/A';
                           }
                        }
                        if(this.more_details && this.more_details.basic_info){
                            this.basic_info=this.more_details.basic_info;
                        }
                    }
                    if(this.enrolment.result_status){
                        this.getUserEnrollmentResult();
                    }
                    this.getUserAllDocuments();

                })
                .catch((err) => {

                })
                .finally(()=>{

                });
        },
        getUserEnrollmentResult(){
            let id =  localStorage.getItem('application_key');
            if(id){
                if(this.showResult==false){
                    this.showResult=true;
                    enrollmentEssentialService
                        .getUserEnrollmentResult(id)
                        .then(response => {
                            this.entranceResult=response.data;

                        })
                        .catch((err) => {

                        })
                        .finally(()=>{

                        });
                }else{
                    this.showResult=false;
                    this.entranceResult=null;
                }
            }

        },
        downloadAdmitCard(){
            let id =  localStorage.getItem('application_key');
            if(id){
                enrollmentEssentialService
                    .downloadUserEnrollmentAdmitCard(id)
            }
        },
        downloadInvoice(){
            let id =  localStorage.getItem('application_key');
            if(id){
                enrollmentEssentialService
                    .downloadUserEnrollmentInvoice(id)
            }
        },
        downloadOfferLetter(){
            let id =  localStorage.getItem('application_key');
            if(id){
                enrollmentEssentialService
                    .downloadOfferLetter(id)
            }
        },
        getUserAllDocuments(){
            let id =  localStorage.getItem('application_key');
            if(id){
                enrollmentEssentialService
                    .getUserAllDocuments(id)
                    .then(response => {
                        this.documents=response.data.documents;


                    })
                    .catch((err) => {

                    })
                    .finally(()=>{

                    });
            }
        },
        acceptEnrolmentOffer(){
            let id =  localStorage.getItem('application_key');
            if(id){
                this.acceptLoading=true;
                enrollmentEssentialService
                    .acceptEnrolmentOffer(id)
                    .then(response => {
                        this.getUserEnrolmentInfoDetail();


                    })
                    .catch((err) => {

                    })
                    .finally(()=>{
                        this.acceptLoading=false;
                    });
            }
        },
        getFileType(fileName) {
            const extension = fileName.split('.').pop().toLowerCase();
            switch (extension) {
                case 'pdf':
                    return 'fas fa-file-pdf';
                case 'xlsx':
                    return 'fas fa-file-excel';
                case 'xls':
                    return 'fas fa-file-excel';
                case 'csv':
                    return 'fas fa-file-excel';
                case 'jpg':
                    return 'fas fa-file-image';
                case 'jpeg':
                    return 'fas fa-file-image';
                case 'png':
                    return 'fas fa-file-image';
                default:
                    return 'fas fa-folder';
            }
        },

    },
    computed: {
        entranceDateStatus() {
            if(this.enrolment && this.enrolment.selected_entrance_date){
                let myDateObj = new Date(this.enrolment.selected_entrance_date);
                let today = new Date();
                if (myDateObj.getTime() < today.getTime()) {
                    return 'past';
                } else if (myDateObj.getTime() > today.getTime()) {
                    return 'future';
                } else {
                    return 'today';
                }
            }else{
                return  null
            }

        }
    },
    mounted() {
        if (localStorage.hasOwnProperty('application_key')) {
            this.getSetting();
            this.getUserEnrolmentInfoDetail();
           /* this.getUserEnrollmentResult();*/
        }else{
            this.$router.push({name: 'index'});
        }
    }
}
</script>
<style scoped>
.result-container {
     max-width: 600px;
     margin: auto;
     border-radius: 10px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
     padding: 24px;
     background-color: #fff;
 }
</style>
<style scoped>
html {
    -webkit-print-color-adjust: exact;
}
body {
    margin: 0in;
    font-family: 'DejaVu Sans', sans-serif;
}
td,th{
    font-family: 'DejaVu Sans', sans-serif;
}

:root {
    --ff-mont: 'DejaVu Sans', sans-serif;
    --color-light-blue: #d4e1f3;
    --color-dark-blue: #00458b;
    --color-light-orange: #fedbb4;
    --color-dark-orange: #f7941d;
    --color-label: #231f20;
    --color-white: #fff;
    --fz-h1: 50px;
    --fz-h2: 30px;
}

*, *::before, *::after {
    box-sizing: border-box;
    border: 0 solid;
}

* {
    margin: 0;
    padding: 0;
}

html, body, main {
    width: 100%;
    height: 100%;
}

body, main {
    display: flex;
    flex-flow: wrap row;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 32px;
    font-weight: bold;
}

h2 {
    font-size: 20px;
    font-weight: bold;
}
.main-wrapper {
    width: 100%;
    padding: 37px 37px;
    min-height: 100%;
    /* margin: auto; */
}
.est-logo {
    float: left;
    max-width: 80px;
    margin-top: -30px;
}

.header-group:after {
    content: '';
    clear: both;
    display: block;
}
h3{
    margin-top: 40px;
}
h3:first-child{
    margin-top: 10px;
}
.header-title {
    margin-top: 0px;
    /*padding-left: 120px;*/
    color: #00458b;
    font-weight: 500;
}
.header-sub-title {
    margin-top: 0px;
    padding-left: 160px;
    color: #ffffff;
    font-weight: 300;
    line-height: 1;
}

.sub-titler-wrapper {
    padding: 8px 37px 12px;
    margin-left: -37px;
    margin-right: -37px;
    background-color: #00458b;
}

.pp-photo {
    width: 120px;
    /* height: 98.88px; */
    border: 3px solid #00458b;
    overflow: hidden;
    float: right;
    margin-right: 100px;
    background-color: #fff;
}
.pp-photo-img {
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
    display: block;
}
.form {
    margin-left: 64px;
    margin-top: 30px;
}
@media only screen and (max-width: 425px) {
    .form {
        margin-left: auto;        
    }
    .badge-compulsary-course{
        background-color:#6c2a9c;
        color: #fff;
        padding: 0.001em 0.002em;
        border-radius: 0.01rem;
    }
    .badge-wrapper{
        width: 100%;
        overflow-x: scroll;
    }

    #education-detail-table{
        display: none;
    }

    #education-detail-list{
        display:block;
    }
}
@media only screen and (min-width: 426px) {
    #education-detail-list{
        display:none;
    }
}
.form .form-group {
    margin-bottom: 10px;
}
.form .form-group:after {
    content: '';
    display: block;
    clear: both;
}
.form .form-label {
    font-size: 15px;
    float: left;
    width: 250px;
    font-weight: 500;
}
.form label {
    font-size: 17px;
    font-weight: 600;
}
.form label + label {
    margin-left: 20px;
}
.form .form-control {
    margin-left: 30px;
    font-size: 15px;
    float: left;
    min-width: 70%;
    border: none;
    border-bottom: 1px solid #00458b;
    background-color: transparent;
    outline: none;
    width: 500px;
}
@media only screen and (max-width: 425px) {
    .form .form-control {
        width: auto;
    }
}
.form .form-control.sm {
    min-width: 245px;
}
.form .form-control.sm + span {
    display: block;
}

.form .mb-10 {
    margin-bottom: 10px;
}

.form .form-checkbox {
    width: 20px;
    height: 20px;
    background-color: white;
    border: none;
    outline: 1px solid #00458b;
    margin-left: 10px;
    /* border-bottom: 1px solid var(--color-dark-blue); */
}

.signature {
    float: right;
    font-size: 17px;
    margin-right: -233px;
    margin-top: 25px;
}

.note {
    font-size: 14px;
    font-style: italic;
}

.second-half {
    margin-top: 5px;
}

.second-half .logo {
    position: absolute;
    left: -170px;
    top: 70%;
    max-width: 130px;
}
.second-half .logo img {
    max-width: 100%;
}

.form-group div.sub-div {
    width: 197px;
    float: left;
    margin-left: 30px;
}
.sub-div.issue_date {
    width: 151px !important;
}

.form-group div.sub-div input  {
    display: block;
    text-align: center;
    float: none;
    margin-left: 0;
    width: 100%;
}
.form-group div.sub-div .form-control.sm {
    min-width: auto;
    max-width: 296px;
}

.form-group div.sub-div input + span,
.form-group div.sub-div input + span + span {
    display: block;
    text-align: center;
    margin-top: 10px;
    font-size: 17px;
    font-style: italic;
    color: #231f20;
}
.form-group div.sub-div *:nth-child(1) {
    float: left;
    height: 24px;
    line-height: 34px;
}
.form-group div.sub-div span + input.sm {
    float: left;
    margin-left: 30px;
    max-width: 115px !important;
}
.applicant_signature .form-control.sm {
    float: right;
    min-width: 228px;
}
.clear {
    display: block;
    clear: both;
}

.official-seal {
    max-width: 150px;
    margin-top: 40px;
}

.official-seal + .sub-div,
.official-seal + .sub-div + .sub-div {
    margin-top: 40px;
}

.official-seal img {
    width: 100%;
    float: left;
}

.no-italic {
    font-style: unset !important;
}

.note-wrapper > * {
    /*float: left;*/
}

.note-wrapper > .note-title {
    /*margin-left: 170px;*/
    margin-top: 30px;
}
.page_break{
    page-break-after: always;
}
.bordered {
    font-family: 'DejaVu Sans', sans-serif;
    border-collapse: collapse;
    width: 100%;
}
.bordered td, .bordered th {
    border: 1px solid #215b96;
    padding: 8px;
    font-size: 14px;
}
.bordered tr:nth-child(even){background-color: #f2f2f2;}
.bordered tr:hover {background-color: #ddd;}
.bordered th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #316499;
    color: white;
    font-size: 12px;
}
.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.badge-compulsary-course{
    background-color:#6c2a9c;
    color: #fff;
    padding: 0.4em 0.6em;
    border-radius: 0.25rem;
}
.badge-recomended-source{
    background-color:#e30e7c;
    color: #fff;
    padding: 0.4em 0.6em;
    border-radius: 1rem;
}
.custom-alert {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
}

.custom-alert.success {
    background-color: #dff0d8;
    color: #3c763d;
}

.custom-alert.danger {
    background-color: #f2dede;
    color: #a94442;
}
.custom-alert {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
}

.custom-alert.success {
    background-color: #dff0d8;
    color: #3c763d;
}
.custom-alert {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
}

.custom-alert.warning {
    background-color: #fcf8e3;
    color: #8a6d3b;
}
.custom-alert {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
}

.custom-alert.info {
    background-color: #d9edf7;
    color: #31708f;
}
.offer-letter-icon-container {
    background-color: #0099ff;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}


</style>
